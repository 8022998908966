import { useDispatch } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { useApp } from "../../hooks/useApp";
import { setIsOpenBurger, setIsOpenHeader } from "../../redux/app/slice";
import { BurgerButton, HeaderStyled, RightContainer } from "./Header.styled";
import { Container } from "../Container/Container";
import { LanguageSelector } from "../LanguageSelector/LanguageSelector";
import clsx from "clsx";
import { useHeader } from "../../hooks/useHeader";
import { useBurger } from "../../hooks/useBurger";
import { ContactLinkButton } from "../ContactLinkButton/ContactLinkButton";
import { NavList } from "../NavList/NavList";
import { Logo } from "../Logo/Logo.jsx";

const Header = () => {
  const dispatch = useDispatch();
  const { isBanner } = useHeader();
  const [isScroll, setIsScroll] = useState(false);
  const { isOpen } = useBurger();

  const { isOpenHeader } = useApp();

  const openBurger = useCallback(() => {
    dispatch(setIsOpenBurger(!isOpen));
  }, [dispatch, isOpen]);

  let lastScrollTop = useRef(0);

  const handleScroll = useCallback(() => {
    let scrollTop = window.scrollY || document.documentElement.scrollTop;
    let tempScroll = scrollTop - lastScrollTop.current;

    let tempUp = -30;
    let tempDown = 10;

    if (tempScroll < 0) {
      // scroll up
      if (!isOpenHeader && tempScroll < tempUp) {
        dispatch(setIsOpenHeader(true));
      }

      if (scrollTop <= 10 && isScroll) {
        setIsScroll(false);
      }
    } else {
      //scroll down
      if (scrollTop >= 80 && !isScroll) {
        setIsScroll(true);
      }

      if (scrollTop >= 100 && isOpenHeader && tempScroll > tempDown) {
        dispatch(setIsOpenHeader(false));
      }
    }

    lastScrollTop.current = scrollTop;
  }, [dispatch, isOpenHeader, isScroll]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <HeaderStyled
      className={clsx(
        !isBanner && "not-banner",
        isScroll && "scroll",
        isOpen && "burger-open"
      )}
    >
      <Container>
        <Logo />
        <nav>
          <NavList className="header-nav" />
        </nav>
        <RightContainer>
          <LanguageSelector />
          <ContactLinkButton />
        </RightContainer>
        <BurgerButton
          type="button"
          onClick={openBurger}
          className={clsx(
            isOpen && "is-open",
            (!isBanner || isScroll || isOpen) && "black"
          )}
        >
          <span></span>
          <span></span>
          <span></span>
        </BurgerButton>
      </Container>
    </HeaderStyled>
  );
};

export default Header;
