import { socialList } from "../../skeleton/socialList";
import svg from "../../img/sprite.svg";
import { SocItem, SocialListStyled } from "./SocialList.styled";

export const SocialList = () => {
  return (
    <SocialListStyled>
      {socialList.map(({ link, icon, type }) => {
        return (
          <SocItem key={type}>
            <a rel="noreferrer" href={link} target="_blank">
              <svg width={20} height={20}>
                <use href={svg + "#icon-" + icon} />
              </svg>
            </a>
          </SocItem>
        );
      })}
    </SocialListStyled>
  );
};
