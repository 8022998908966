import { phoneNumbers } from "../../skeleton/contacts";
import svg from "../../img/sprite.svg";
import { useApp } from "../../hooks/useApp";
import { ContactsContainer, PhoneContainer } from "./ContactsData.styled";

export const ContactsData = ({
  phoneClassName,
  contactClassName,
  isAddress = true,
}) => {
  const {
    schema: { address },
  } = useApp();

  return (
    <>
      <PhoneContainer className={phoneClassName}>
        <div className="icon-phone">
          <svg width={20} height={20}>
            <use href={svg + "#icon-phone"} />
          </svg>
        </div>
        <ul>
          {phoneNumbers.map(({ number, label }) => (
            <li key={number}>
              <a rel="noreferrer" href={`tel:${number}`}>
                {label}
              </a>
            </li>
          ))}
        </ul>
      </PhoneContainer>
      <ContactsContainer className={contactClassName}>
        <ul>
          <li>
            <svg width={20} height={20}>
              <use href={svg + "#icon-message"} />
            </svg>
            <a rel="noreferrer" href="mailto:peoplepulse.hragency@gmail.com">
              peoplepulse.hragency@gmail.com
            </a>
          </li>
          {isAddress && (
            <li>
              <svg width={20} height={20}>
                <use href={svg + "#icon-map"} />
              </svg>
              <p>{address}</p>
            </li>
          )}
        </ul>
      </ContactsContainer>
    </>
  );
};
