import { useMemo, useState } from "react";
import { useApp } from "../../../hooks/useApp";
import { useDispatch } from "react-redux";
import { setLaguage } from "../../../redux/app/slice";
import { Button, Container, List } from "./BurgerLanguageSelector.styled.js";
import svg from "../../../img/sprite-app.svg";

export const BurgerLanguageSelector = () => {
  const { language } = useApp();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const { languageList, languageCount } = useMemo(() => {
    const list = [
      { type: "en", name: "EN" },
      { type: "ua", name: "UA" },
    ].filter(({ type }) => type !== language);

    const count = list.length;

    return { languageList: list, languageCount: count };
  }, [language]);

  const handleClick = (type) => {
    dispatch(setLaguage(type));
  };

  const containerHeight = useMemo(() => {
    if (isOpen) {
      return 25 + languageCount * 25 + "px";
    }
    return "25px";
  }, [isOpen, languageCount]);

  return (
    <Container $height={containerHeight}>
      <Button type="button" onClick={() => setIsOpen((prev) => !prev)}>
        <svg width={20} height={20}>
          <use href={svg + "#icon-world-white"} />
        </svg>
        {language}
      </Button>
      <List className="lang-list">
        {languageList.map(({ type, name }) => {
          return (
            <li key={type}>
              <Button
                className="lang-btn"
                type="button"
                onClick={() => handleClick(type)}
              >
                {name}
              </Button>
            </li>
          );
        })}
      </List>
    </Container>
  );
};
