import { Link } from "react-router-dom";
import styled from "styled-components";

export const FooterStyled = styled.footer`
  width: 100%;
  background-color: var(--color);
  grid-area: footer;
  transition: all var(--transition);
  color: white;
  border-radius: 32px 32px 0px 0px;
  padding: 40px 0 40px;

  & .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 250px 380px;
    gap: 15px;

    & .footer-nav {
      flex-direction: column;
      font-size: 20px;

      & .active {
        color: var(--accent);
        pointer-events: none;
      }
    }

    @media screen and (width < 1280px) {
      grid-template-columns: 1fr;
      place-items: center;
    }
  }
`;

export const LinkStyled = styled(Link)`
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  transition: color var(--transition);

  @media (hover: hover) {
    &:hover {
      color: var(--accent);
    }
  }
`;

export const SocContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (width < 1280px) {
    justify-content: center;
    align-items: center;
  }
`;
