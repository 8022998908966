import styled from "styled-components";

export const SocialListStyled = styled.ul`
  display: flex;
  gap: 15px;
`;

export const SocItem = styled.li`
  & a {
    & svg {
      fill: currentColor;
      transition: fill var(--transition);
    }

    @media (hover: hover) {
      &:hover {
        & svg {
          fill: var(--accent);
        }
      }
    }
  }
`;
