import styled from "styled-components";

export const PhoneContainer = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 15px;

  & .icon-phone {
  }

  & ul {
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media screen and (width < 1280px) {
      justify-content: center;
      align-items: left;
    }

    & li {
      & a {
        font-size: 20px;
        font-weight: 500;
        line-height: 150%;

        @media (hover: hover) {
          &:hover {
            color: var(--accent);
          }
        }

        @media screen and (width < 1280px) {
          font-size: 16px;
        }
      }
    }
  }

  & svg {
    margin-top: 6px;
    fill: currentColor;
  }
`;

export const ContactsContainer = styled.address`
  & ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-style: normal;
    font-size: 20px;
    font-weight: 500;

    @media screen and (width < 1280px) {
      font-size: 16px;
    }

    & li {
      display: flex;
      align-items: center;
      gap: 15px;
      & svg {
        /* margin-top: 6px; */
        fill: currentColor;
      }

      & a {
        font-size: 20px;
        font-weight: 500;
        line-height: 150%;

        @media (hover: hover) {
          &:hover {
            color: var(--accent);
          }
        }

        @media screen and (width < 1280px) {
          font-size: 16px;
        }
      }
    }
  }
`;
