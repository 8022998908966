import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    header: {
      isOpen: true,
      isBanner: false,
    },
    burger: {
      isOpen: false,
    },
    language: "ua",
  },
  reducers: {
    setIsOpenHeader(state, { payload }) {
      state.header.isOpen = payload;
    },
    setIsBannerHeader(state, { payload }) {
      state.header.isBanner = payload;
    },
    setLaguage(state, { payload }) {
      state.language = payload;
    },
    setIsOpenBurger(state, { payload }) {
      state.burger.isOpen = payload;
    },
  },
});

export const appReducer = appSlice.reducer;
export const {
  setIsOpenHeader,
  setLaguage,
  setIsBannerHeader,
  setIsOpenBurger,
} = appSlice.actions;
