import { useSelector } from "react-redux";
import schema from "../languages.json";

export const useApp = () => {
  const { language } = useSelector((state) => state.app);

  return {
    language,
    schema: schema[language],
  };
};
