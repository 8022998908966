export const socialList = [
  // {
  //   link: "https://www.facebook.com/CityStaffHR",
  //   type: "facebook",
  //   icon: "facebook",
  // },
  // {
  //   link: "https://www.instagram.com/hr_citystaff_ua",
  //   type: "instagram",
  //   icon: "instagram",
  // },
  {
    link: "https://t.me/viktor_people_pulse",
    type: "telegram",
    icon: "telegram",
  },
];
