import { useApp } from "../../hooks/useApp";
import { navList } from "../../skeleton/nav";
import { LinkStyled, NavListStyled } from "./NavList.styled";

export const NavList = ({ className, hanleClick = () => {} }) => {
  const {
    schema: { nav },
  } = useApp();
  return (
    <NavListStyled className={className}>
      {navList.map(({ link }) => (
        <li key={link}>
          <LinkStyled to={"/" + link} onClick={hanleClick}>
            {nav[link]}
          </LinkStyled>
        </li>
      ))}
    </NavListStyled>
  );
};
