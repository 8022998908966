import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeaderStyled = styled.header`
  width: 100%;
  background-color: transparent;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  grid-area: header;
  transition: all var(--transition);
  color: white;

  &.scroll,
  &.burger-open {
    box-shadow: 0px 0px 8px 1px rgb(0 0 0 / 10%);
    border-radius: 0 0 32px 32px;
  }

  &.scroll,
  &.not-banner,
  &.burger-open {
    background-color: white;
    color: var(--color);

    & .link-btn {
      border-color: var(--accent);
      color: var(--accent);

      @media (hover: hover) {
        /* when hover is supported */
        &:hover {
          color: white;
        }
      }
    }
  }

  .container {
    min-height: 78px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px 30px;

    & .header-nav {
      font-size: 16px;
      @media screen and (width < 1280px) {
        display: none;
      }

      & .active {
        color: var(--accent);
        pointer-events: none;
        text-shadow: 0 0 2px rgba(255, 255, 255, 0.25);
      }
    }
  }

  &.not-home-page {
    color: var(--color);
  }
`;

export const RightContainer = styled.div`
  display: grid;
  gap: 5px;
  align-items: center;
  grid-template-columns: 75px 220px;
  justify-items: center;

  @media screen and (width < 1280px) {
    display: none;
  }
`;

export const LinkButtonStyled = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  display: inline-flex;
  align-items: center;
  min-height: 38px;
  border: 2px solid white;
  border-radius: 40px;
  padding: 6px 16px;

  @media (hover: hover) {
    /* when hover is supported */
    &:hover {
      background-color: var(--accent);
      border-color: var(--accent);
    }
  }
`;

export const BurgerButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;

  & span {
    background: #fff;
    border-radius: 10px;
    height: 2px;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);

    &:nth-of-type(1) {
      width: 50%;
    }

    &:nth-of-type(2) {
      width: 100%;
    }

    &:nth-of-type(3) {
      width: 75%;
    }
  }

  &.is-open {
    & span {
      &:nth-of-type(1) {
        transform-origin: bottom;
        transform: rotatez(45deg) translate(4px, 1px);
      }

      &:nth-of-type(2) {
        transform-origin: top;
        transform: rotatez(-45deg);
      }

      &:nth-of-type(3) {
        transform-origin: bottom;
        width: 50%;
        transform: translate(9px, -5px) rotatez(45deg);
      }
    }
  }

  &.black {
    & span {
      background-color: #000;
    }
  }

  @media screen and (width >= 1280px) {
    display: none;
  }
`;
