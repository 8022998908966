import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
     :root {
          --transition: 200ms ease;
          --body-background: white;
          --color: #333333;
          --accent: #08b4a4;
          --second-accent: #11b4b3;
          --border-radius: 40px
     }

     body {
          font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          background-color: var(--body-background);
          color: var(--color);
          fill: var(--color);

          &::-webkit-scrollbar {
               width: 8px;             /* width of the entire scrollbar */

          }

          &::-webkit-scrollbar-track {
               background: var(--body-background); 
  
          }

          &::-webkit-scrollbar-thumb {
               background-color: var(--color);
               border-radius: 10px;       /* roundness of the scroll thumb */
               border: 1px solid var(--body-background);  /* creates padding around scroll thumb */

               &:hover {
                    background-color: var(--accent);
               }
          }
     }    

     code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
          monospace;
     }

     input::-webkit-outer-spin-button,
     input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
     }

     svg {
          fill: inherit;
          stroke: inherit
     }
     
`;
