import { Link } from "react-router-dom";
import styled from "styled-components";

export const LinkButtonStyled = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  display: inline-flex;
  align-items: center;
  min-height: 38px;
  border: 2px solid currentColor;
  border-radius: 40px;
  padding: 6px 16px;
  transition: all var(--transition);

  @media (hover: hover) {
    /* when hover is supported */
    &:hover {
      background-color: var(--accent);
      border-color: var(--accent);
    }
  }
`;
