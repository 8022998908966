import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  padding: 10px 0;

  @media (hover: hover) {
    &:hover {
      .lang-list {
        transform: translate(-50%, 0);
        opacity: 1;
      }
    }
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -10px);
  padding: 15px 20px;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05);
  background-color: var(--body-background);
  transition: all var(--transition);
  opacity: 0;
  border-radius: 20px;
`;

export const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 600;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;

  &.lang-btn {
    color: var(--color);
  }

  & svg {
    fill: transparent;
    stroke: currentColor;
  }
`;
