import { useSelector } from "react-redux";

export const useHeader = () => {
  const { isOpen, isBanner } = useSelector((state) => state.app.header);

  return {
    isOpen,
    isBanner,
  };
};
