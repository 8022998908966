import { useMemo } from "react";
import { useApp } from "../../hooks/useApp";
import { useDispatch } from "react-redux";
import { setLaguage } from "../../redux/app/slice";
import { Button, Container, List } from "./LanguageSelector.styled.js";
import svg from "../../img/sprite-app.svg";

export const LanguageSelector = () => {
  const { language } = useApp();
  const dispatch = useDispatch();

  const languageList = useMemo(() => {
    return [
      { type: "en", name: "EN" },
      { type: "ua", name: "UA" },
    ].filter(({ type }) => type !== language);
  }, [language]);

  const handleClick = (type) => {
    dispatch(setLaguage(type));
  };

  return (
    <Container>
      <Button type="button">
        <svg width={20} height={20}>
          <use href={svg + "#icon-world-white"} />
        </svg>
        {language}
      </Button>
      <List className="lang-list">
        {languageList.map(({ type, name }) => {
          return (
            <li key={type}>
              <Button
                className="lang-btn"
                type="button"
                onClick={() => handleClick(type)}
              >
                {name}
              </Button>
            </li>
          );
        })}
      </List>
    </Container>
  );
};
