import { useApp } from "../../hooks/useApp";
import { LinkButtonStyled } from "./ContactLinkButton.styled";

export const ContactLinkButton = ({
  className = "link-btn",
  hanleClick = () => {},
}) => {
  const {
    schema: { get_consultation },
  } = useApp();

  return (
    <LinkButtonStyled
      to={"/contacts"}
      className={className}
      onClick={hanleClick}
    >
      {get_consultation}
    </LinkButtonStyled>
  );
};
