import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  overflow: hidden;
  height: ${({ $height }) => $height};
  transition: all var(--transition);
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: all var(--transition);
`;

export const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 600;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;

  &.lang-btn {
    color: var(--color);
  }

  & svg {
    fill: transparent;
    stroke: currentColor;
  }
`;
