import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavListStyled = styled.ul`
  display: flex;
  gap: 5px;
`;

export const LinkStyled = styled(Link)`
  font-weight: 600;
  line-height: 140%;
  text-align: center;
  display: inline-flex;
  padding: 5px 5px;
  transition: color var(--transition);

  &:hover {
    text-decoration: underline;
  }
`;
