import { Link } from "react-router-dom";
import styled from "styled-components";

export const LinkStyled = styled(Link)`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  &.column {
    gap: 5px;
    flex-direction: column;

    & div {
      align-items: center;
    }

    & img {
      filter: none;
    }
  }

  & img {
    filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.2));
  }

  & div {
    display: flex;
    flex-direction: column;
    gap: 2px;

    & p {
      font-size: 9px;
      font-weight: 500;
    }
  }
`;
