import { BurgerStyled } from "./Burger.styled";
import { useDispatch } from "react-redux";
import { setIsOpenBurger } from "../../redux/app/slice";
import { ContactLinkButton } from "../ContactLinkButton/ContactLinkButton";
import { Container } from "../Container/Container";
import { NavList } from "../NavList/NavList";
import { BurgerLanguageSelector } from "./BurgerLanguageSelector/BurgerLanguageSelector";

export const Burger = ({ className }) => {
  const dispatch = useDispatch();

  const closeBurger = () => {
    dispatch(setIsOpenBurger(false));
  };

  return (
    <BurgerStyled className={className}>
      <Container>
        <nav>
          <NavList className="burger-nav" hanleClick={closeBurger} />
        </nav>
        <BurgerLanguageSelector />
        <ContactLinkButton hanleClick={closeBurger} />
      </Container>
    </BurgerStyled>
  );
};
