import { useDispatch } from "react-redux";
import svg from "../../img/logo.svg";
import { LinkStyled } from "./Logo.styled.js";
import { setIsOpenBurger } from "../../redux/app/slice.js";

export const Logo = ({ className }) => {
  const dispatch = useDispatch();

  const closeBurger = () => {
    dispatch(setIsOpenBurger(false));
  };
  return (
    <LinkStyled to={"/"} onClick={closeBurger} className={className}>
      <img src={svg} alt="logo" width={40} height={40} />
      <div>
        <h4>PeoplePulse</h4>
        <p>HR AGENCY</p>
      </div>
    </LinkStyled>
  );
};
