import { useApp } from "../../hooks/useApp";
import { Container } from "../Container/Container";
import { LinkStyled, SocContainer } from "./Footer.styled";
import { FooterStyled } from "./Footer.styled";
import { NavList } from "../NavList/NavList";
import { ContactsData } from "../ContactsData/ContactsData";
import { SocialList } from "../SocialList/SocialList";
import { Logo } from "../Logo/Logo.jsx";

export const Footer = () => {
  const {
    schema: { privacy_policy },
  } = useApp();
  return (
    <FooterStyled>
      <Container>
        <div>
          <Logo className="column" />
        </div>
        <NavList className="footer-nav" />
        <SocContainer>
          <LinkStyled to={"/privacy-policy"}>{privacy_policy}</LinkStyled>
          <SocialList />
        </SocContainer>
        <ContactsData />
      </Container>
    </FooterStyled>
  );
};
